import '@fontsource/inter'

const theme = {
  space: [0, 4, 8, 16, 32, 64, 96, 128, 256, 512],
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'Inter, system-ui, sans-serif',
  },
  fontSizes: [12, 14, 16, 20, 28, 32, 48, 64, 96],
  lineHeights: {
    body: 1.5,
    heading: 1.5,
  },
  colors: {
    text: '#eeeeee',
    background: '#000000',
    primary: '#e63051',
    footer: '#222222',
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    b: {
      color: 'primary',
      fontWeight: 900,
    },
  },
  sizes: {
    container: 1200,
  }
}

export default theme
